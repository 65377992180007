import React from "react";
//
import * as css from "./PlayButton.module.css";

export default function PlayButton({ url }) {

  const playMusic = (musicUrl) => {
    const music = new Audio(musicUrl);
    music.play();
  }
  
  return (
  <button className={css.container} onClick={() => playMusic(url)}>
    <div className={css.button} />
  </button>
  );
}
