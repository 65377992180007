import React from "react";
import CodeHighlight from "@rocketseat/gatsby-theme-docs/src/components/Code";

export default function Actor({ actor }) {
  return (
    <>
      <h2>{actor.displayName}</h2>

      <img src={actor.images.thumbnail} style={{ maxWidth: 270 }} />
      <p>
        Set the following string as a value for the actor property in your actor
        tracks to use {actor.displayName}:
      </p>
      <CodeHighlight codeString={actor.name} />
    </>
  );
}
