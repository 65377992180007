import React from "react";
//
import * as css from "./EmbeddedVideo.module.css";

export default function EmbeddedVideo({ sources, previewSource }) {
  
  return (
    <video controls className={css.fillAvailable}>
      {
        sources.map(src => <source src={src} />)
      }
      <p>
        Your browser doesn't support HTML5 video. Here is a{" "}
        <a href={previewSource}>link to the preview video</a>{" "}
        instead.
      </p>
    </video>
  );
}
